function initializeHeader() {

  const mainHeader = document.querySelector("#main-header");
  const topLevelLinkItems = document.querySelectorAll("#main-header .cmp-navigation__item--level-0");
  const flyOutMenuNavs = document.querySelectorAll("#main-header .flyout-nav");

  // grab the internationalized strings for expanded and collapsed, with English as fallback
  const collapsedString = topLevelLinkItems[0].getAttribute("data-collapsed-string") || "collapsed"; 
  const expandedString = topLevelLinkItems[0].getAttribute("data-expanded-string") || "expanded"; 
  
  /*
  ** Keyboard controls for top level links
  */
  function handleTopLevelKeydown(e) {
    const currentLinkIndex = Number(e.currentTarget.id.replace("link-", ""));
    const prevLink = e.currentTarget.closest(".cmp-container").querySelector(`#link-${currentLinkIndex-1}`);

    switch (e.which) {
      case 13: //enter
        const parent = e?.target?.parentElement;
        const hasChildren = parent?.querySelector('.cmp-navigation__group')
        // only override regular behavior if top-level links have child links  
        if(hasChildren){
          e.preventDefault();
          togglePanel(e);
        }
        break;
      case 32: //spacebar
        e.preventDefault();
        expandPanel(e);
        break;
      case 37: //left
        e.preventDefault();
        if (prevLink) {
          prevLink.focus();
        }
        break;
      case 38: //up
        e.preventDefault();
        collapseAllPanels(e);
        if (prevLink) {
          prevLink.focus();
        }
        break;
      case 39: //right
        e.preventDefault();
        const nextLink = e.currentTarget.closest(".cmp-container").querySelector(`#link-${currentLinkIndex+1}`);
        if (nextLink) {
          nextLink.focus();
        }
        break;
      case 40: //down
        e.preventDefault();
        expandPanel(e);
        break;
    }
  }

  /*
  ** Keyboard controls for child links
  */
  function handleSecondLevelKeydown(e) {
    const currentLinkIndices = e.currentTarget.id.replace("link-", "").split("-");
    
    function focusPrevious(e) {
      const prevLink = e.currentTarget.closest(".cmp-navigation__item--level-0").querySelector(`#link-${currentLinkIndices[0]}-${Number(currentLinkIndices[1])-1}`);
      const prevTopLink = e.currentTarget.closest(".cmp-container").querySelector(`#link-${Number(currentLinkIndices[0])}`);
      if (prevLink) {
        prevLink.focus();
      } else if (prevTopLink) {
        prevTopLink.focus();
      }
    }
    function focusNext(e, direction) {
      const nextLink = e.currentTarget.closest(".cmp-navigation__item--level-0").querySelector(`#link-${currentLinkIndices[0]}-${Number(currentLinkIndices[1])+1}`);
      const nextElement = e.currentTarget.nextElementSibling;
      const nextChildLink = e.currentTarget.closest(".cmp-navigation__item--level-0").querySelector(`#link-${currentLinkIndices[0]}-${Number(currentLinkIndices[1])+1}`);
      const nextTopLink = e.currentTarget.closest(".cmp-container").querySelector(`#link-${Number(currentLinkIndices[0])+1}`);
      if (direction === "right" && nextChildLink) {
        nextChildLink.focus();
      } else if (nextElement && nextElement.tagName === "BUTTON") {
        expandFlyout(e);
        nextElement.nextElementSibling.querySelector("a").focus();
      } else if (nextElement && nextElement.classList.contains("cmp-navigation__group")) {
        nextElement.querySelector("a").focus();
      } else if (nextLink) {
        nextLink.focus();
      } else if (nextTopLink) {
        nextTopLink.focus();
      }
    }

    switch (e.which) {
      case 32: //spacebar
        e.preventDefault();
        focusNext(e);
        break;
      case 37: //left
        e.preventDefault();
        focusPrevious(e);
        break;
      case 38: //up
        e.preventDefault();
        focusPrevious(e);
        break;
      case 39: //right
        e.preventDefault();
        focusNext(e, "right");
        break;
      case 40: //down
        e.preventDefault();
        focusNext(e);
        break;
    }
  }

  /*
  ** Keyboard controls for grandchild links
  */
  function handleThirdLevelKeydown(e) {
    const currentLinkIndices = e.currentTarget.id.replace("link-", "").split("-");

    function focusPrevious(e) {
      const prevLink = e.currentTarget.closest(".cmp-navigation__item--level-1").querySelector(`#link-${currentLinkIndices[0]}-${currentLinkIndices[1]}-${Number(currentLinkIndices[2])-1}`);
      const prevChildLink = e.currentTarget.closest(".cmp-navigation__item--level-0").querySelector(`#link-${currentLinkIndices[0]}-${Number(currentLinkIndices[1])}`);
      if (prevLink) {
        prevLink.focus();
      } else if (prevChildLink) {
        prevChildLink.focus();
      }
    }
    function focusNext(e) {
      const nextLink = e.currentTarget.closest(".cmp-navigation__item--level-1").querySelector(`#link-${currentLinkIndices[0]}-${currentLinkIndices[1]}-${Number(currentLinkIndices[2])+1}`);
      const nextChildLink = e.currentTarget.closest(".cmp-navigation__item--level-0").querySelector(`#link-${currentLinkIndices[0]}-${Number(currentLinkIndices[1])+1}`);
      const nextTopLink = e.currentTarget.closest(".cmp-container").querySelector(`#link-${Number(currentLinkIndices[0])+1}`);
      if (nextLink) {
        nextLink.focus();
      } else if (nextChildLink) {
        nextChildLink.focus();
      } else if (nextTopLink) {
        nextTopLink.focus();
      }
    }

    switch (e.which) {
      case 37: //left
        e.preventDefault();
        focusPrevious(e);
        break;
      case 38: //up
        e.preventDefault();
        focusPrevious(e);
        break;
      case 39: //right
        e.preventDefault();
        focusNext(e);
        break;
      case 40: //down
        e.preventDefault();
        focusNext(e);
        break;
    }
  }

  /*
  ** Panel and Flyout Collapse All controls
  */
  function collapseAllPanels(e) {
    e.currentTarget
      .closest(".cmp-container")
      .querySelectorAll(".is-expanded")
      .forEach(expandedList => {
        expandedList.classList.remove("is-expanded");
        const srSpan = expandedList.querySelector(":scope > a > span.sr-only");
        if(srSpan){
          srSpan.innerText= collapsedString;
          srSpan.setAttribute("aria-live", "off");
        }
      })
  }

  function collapseAllFlyouts(e) {
    e.currentTarget
      .closest(".cmp-navigation__item--level-0")
      .querySelectorAll(".is-expanded")
      .forEach(expandedList => {
        expandedList.classList.remove("is-expanded");
        const srSpan = expandedList.querySelector(":scope > a > span.sr-only");
        if(srSpan){
          srSpan.innerText= collapsedString;
          srSpan.setAttribute("aria-live", "off");
        }
      })
  }

  function handleTopLevelFocus(e) {
    collapseAllPanels(e);
  }

  function handleSecondLevelFocus(e) {
    collapseAllFlyouts(e);
  }

  /*
  ** Second Level panel functions
  */
  function togglePanel(e) {
    const parentLinkItem = e.currentTarget.closest(".cmp-navigation__item--level-0");

    if (parentLinkItem.classList.contains("is-expanded")) {
      collapsePanel(e, parentLinkItem);
    } else {
      expandPanel(e, parentLinkItem);
    }
  }
  function collapsePanel(e, parentLinkItem) {
    const parentLinkItemToUse = parentLinkItem || e.currentTarget.closest(".cmp-navigation__item--level-0");
    const parentLink = parentLinkItemToUse.querySelector(":scope > a");

    if (parentLink.getAttribute("aria-haspopup") === "true") {
      parentLinkItemToUse.classList.remove("is-expanded");
      const srSpan = parentLinkItemToUse.querySelector(":scope > a > span.sr-only");
      if(srSpan){
        srSpan.innerText= collapsedString;
      }
    }
  }
  function expandPanel(e, parentLinkItem) {
    // Collapse Any panels left open
    collapseAllPanels(e);

    const parentLinkItemToUse = parentLinkItem || e.currentTarget.closest(".cmp-navigation__item--level-0");
    const parentLink = parentLinkItemToUse.querySelector(":scope > a");

    if (parentLink.getAttribute("aria-haspopup") === "true") {
      parentLinkItemToUse.classList.add("is-expanded");
      const srSpan = parentLinkItemToUse.querySelector(":scope > a > span.sr-only");
      if(srSpan){  
        srSpan.setAttribute("aria-live", "polite");
        srSpan.innerText = expandedString;
      }
      
      // Focus fist link in list after expanding
      window.setTimeout(() => {
        const firstPanelLink = parentLinkItemToUse.querySelector(".cmp-navigation__item--level-1 > a");
        if (firstPanelLink) {
          firstPanelLink.focus();
        }
      }, 700);
    }
  }



  /*
  ** Initialize Third Level Flyout Menu
  */
  function toggleFlyout(e) {
    const parentLinkItem = e.currentTarget.closest(".cmp-navigation__item--level-1");

    if (parentLinkItem.classList.contains("is-expanded")) {
      collapseFlyout({ ...e, currentTarget: parentLinkItem});
    } else {
      expandFlyout(e, parentLinkItem);
    }
  }
  function collapseFlyout(e) {
    e.currentTarget.classList.remove("is-expanded");
    const srSpan = e.currentTarget.querySelector(":scope > a > span.sr-only");
    if(srSpan){
      srSpan.innerText= collapsedString;
      srSpan.setAttribute("aria-live", "off");
    }
  }
  function expandFlyout(e, parentLinkItem) {
    collapseAllFlyouts(e);
    const parentLinkItemToUse = parentLinkItem || e.currentTarget.closest(".cmp-navigation__item--level-1");
    parentLinkItemToUse.classList.add("is-expanded");
    const srSpan = parentLinkItemToUse.querySelector(":scope > a > span.sr-only")
    if(srSpan){
      srSpan.setAttribute("aria-live", "polite");
      srSpan.innerText= expandedString;
    }
  }

  flyOutMenuNavs.forEach((flyoutNav, i) => {
    const level1Items = flyoutNav.querySelectorAll(".cmp-navigation__item--level-1");

    level1Items.forEach((level1Item, j) => {
      const flyoutMenuList = level1Item.querySelector(".cmp-navigation__group");

      if (flyoutMenuList) {
        level1Item.addEventListener("mouseleave", collapseFlyout, false);

        const itemLink = level1Item.querySelector(".cmp-navigation__item-link");
        itemLink.id = `flyout-link-${i}-${j}`;

        const menuTriggerButton = document.createElement('button');
        menuTriggerButton.setAttribute("tabindex", "-1");
        menuTriggerButton.addEventListener("mouseover", expandFlyout, false);
        menuTriggerButton.addEventListener("click", toggleFlyout, false);
        menuTriggerButton.innerHTML = `
        <svg width="14" height="14" viewBox="0 0 14 14">
          <g transform="translate(-691 -953)">
            <rect width="2" height="14" transform="translate(697 953)" fill="#2981EB"/>
            <rect width="2" height="14" transform="translate(705 959) rotate(90)" fill="#2981EB"/>
          </g>
        </svg>
        `;
        itemLink.after(menuTriggerButton);
      }

    })
  })

  /*
  ** Initialize Link panels
  */
  topLevelLinkItems.forEach((topLevelLinkItem, i) => {
    const topLevelLinkPanel = topLevelLinkItem.closest(".cmp-navigation__group");
    const linkPanel = topLevelLinkItem.querySelector(".cmp-navigation__group");
    const topLevelLink = topLevelLinkItem.querySelector(".cmp-navigation__item-link");

    // Assign ARIA Attributes
    topLevelLinkPanel.setAttribute("role", "menu");
    topLevelLink.parentElement.setAttribute("role", "presentation");
    topLevelLink.id = `link-${i}`;
    topLevelLink.setAttribute("role", "menuitem");
    topLevelLink.setAttribute("aria-setsize", topLevelLinkItems.length);
    topLevelLink.setAttribute("aria-posinset", i+1);
    topLevelLink.addEventListener("keydown", handleTopLevelKeydown, false);
    topLevelLink.addEventListener("focus", handleTopLevelFocus, false);

    if (linkPanel) {
      // create a sr-only div
      const srSpan = document.createElement('span');
      srSpan.classList.add("sr-only");
      srSpan.setAttribute("aria-live", "off");
      srSpan.innerText = collapsedString;
      topLevelLink.appendChild(srSpan);

      linkPanel.setAttribute("role", "menu");
      topLevelLink.setAttribute("aria-haspopup", "true");

      const linkPanelChildEls = linkPanel.querySelectorAll(".cmp-navigation__item--level-1");
      linkPanel.setAttribute("data-childlinks", linkPanelChildEls.length);
      
      // For Second Teir Nav
      linkPanelChildEls.forEach((childLinkItem, j) => {
        const childLink = childLinkItem.querySelector("a");
        if (childLink) {
          // Assign ARIA Attributes
          childLink.parentElement.setAttribute("role", "presentation");
          childLink.id = `link-${i}-${j}`;
          childLink.setAttribute("role", "menuitem");
          childLink.setAttribute("aria-setsize", linkPanelChildEls.length);
          childLink.setAttribute("aria-posinset", j+1);
          const nextElement = childLink.nextElementSibling;
          if (nextElement && nextElement.tagName === "BUTTON") {
            childLink.setAttribute("aria-haspopup", "true");
          }

          childLink.addEventListener("keydown", handleSecondLevelKeydown, false);
          childLink.addEventListener("focus", handleSecondLevelFocus, false);
        }

        const grandChildNavGroup = childLinkItem.querySelector(".cmp-navigation__group");
        if (grandChildNavGroup) {
          childLinkItem.setAttribute(`data-grandchildren`, grandChildNavGroup.childElementCount);
          grandChildNavGroup.setAttribute("role", "menu");
        }
        // For Third Teir Nav
        const grandChildLinks = childLinkItem.querySelectorAll(".cmp-navigation__item--level-2 > a");
        grandChildLinks.forEach((grandChildLink, k) => {
          // Assign ARIA Attributes
          grandChildLink.parentElement.setAttribute("role", "presentation");
          grandChildLink.id = `link-${i}-${j}-${k}`;
          grandChildLink.setAttribute("role", "menuitem");
          grandChildLink.setAttribute("aria-setsize", grandChildLinks.length);
          grandChildLink.setAttribute("aria-posinset", k+1);

          grandChildLink.addEventListener("keydown", handleThirdLevelKeydown, false);
        });

      })
    }
  })

  // Handle Animation to smaller nav on scroll from top

  let atPageTop = true;

  document.addEventListener("scroll", function (e) {
    const scrollY = window?.scrollY;
    if (scrollY > 3 && atPageTop) {
      atPageTop = false;
      mainHeader.classList.add("not-page-top");
    } else if (scrollY < 3 && !atPageTop) {
      atPageTop = true;
      mainHeader.classList.remove("not-page-top");
    }
  })
}

if (document.readyState !== 'loading') {
  initializeHeader();
} else {
  document.addEventListener('DOMContentLoaded', function () {
      initializeHeader()
  });
}
