function initializeMobileNav() {
  "use strict";

  let navItemHtml = "",
      topLevelNavItems =  document.querySelectorAll("#main-header .cmp-navigation__item--level-0"),
      bodyEl = document.getElementsByTagName("body"),
      mainHeader = document.getElementById("main-header");

  // Insert Mobilenav Toggle into header
  const mobileNavTrigger = document.createElement("div");
  mobileNavTrigger.id = "mobile-nav_trigger";
  mobileNavTrigger.innerHTML = `
    <div id="nav-icon3">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>`;
  mainHeader.append(mobileNavTrigger);

  // Compile html for mobile nav items
  topLevelNavItems.forEach(navItem => {
    const tempWrapper = document.createElement('div');
    tempWrapper.innerHTML = navItem.outerHTML;
    tempWrapper.querySelectorAll("a + button").forEach(expandBtn => expandBtn.remove());
    navItemHtml += tempWrapper.innerHTML.replaceAll(`id="`, `id="mobile-`);
  })

  // Insert mobile nav code into header
  const mobileNavEl = document.createElement("div");
  mobileNavEl.id = "mobileNav";
  mobileNavEl.setAttribute("class", "cmp-navigation--mobile");
  mobileNavEl.innerHTML = `<ul class="cmp-navigation-mobile_nav list-collapsed" id="mobile-nav-list">${navItemHtml}</ul>`
  bodyEl[0].append(mobileNavEl);

  // Expand/Collapse function
  function handleNavItemClick (e) {
    const targetClassList = e.currentTarget.classList;
    const targetIsExpanded = targetClassList.contains('isExpanded');
    const expandedEls = document.querySelectorAll("#mobile-nav-list li.cmp-navigation__item--level-0.isExpanded");

    expandedEls.forEach(expandedEl => {
      expandedEl.classList.remove('isExpanded');
      const childLink = expandedEl.querySelector('a');
      childLink.setAttribute('aria-expanded', 'false');
    });

    if (!targetIsExpanded) {
      targetClassList.add('isExpanded');
      const childLink = e.currentTarget.querySelector('a');
      childLink.setAttribute('aria-expanded', 'true');
    }
  }

  // 0-level list item click trigger
  document.querySelectorAll("#mobile-nav-list li.cmp-navigation__item--level-0")
    .forEach(topLevelNavItem => topLevelNavItem.addEventListener('click', handleNavItemClick, false));

  // mobile toggle animation
  document.getElementById("nav-icon3").addEventListener("click", e => {
    const navToggleClassList = e.currentTarget.classList;
    const navList = document.getElementById("mobile-nav-list");
    const navListClassList = navList.classList;

    if (navToggleClassList.contains("open")) {
      navToggleClassList.remove("open");
    } else {
      navToggleClassList.add("open")
    }

    if (navListClassList.contains("list-collapsed")) {
      navListClassList.remove("list-collapsed");
    } else {
      navListClassList.add("list-collapsed")
    }

  });

};

if (document.readyState !== 'loading') {
  initializeMobileNav();
} else {
  document.addEventListener('DOMContentLoaded', function () {
      initializeMobileNav()
  });
}
