
// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png"
import "./resources/images/vhfc_favicon_70x70.png"
import "./resources/images/vhfc_favicon_120x120.png"
import "./resources/images/vhfc_favicon_150x150.png"
import "./resources/images/vhfc_favicon_152x152.png"
import "./resources/images/vhfc_favicon_167x167.png"
import "./resources/images/vhfc_favicon_180x180.png"
import "./resources/images/vhfc_favicon_192x192.png"
import "./resources/images/vhfc_favicon_310x150.png"
import "./resources/images/vhfc_favicon_310x310.png"
// Component Javascript
import "./components/experiencefragment/change_flag.js";
import "./components/experiencefragment/initializeHeaderNav.js";
import "./components/navigation/renderMobileNav.js";
import "./components/carousel/_carousel.js";