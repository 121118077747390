import jQuery from "jquery";
import Splide from "@splidejs/splide";

jQuery(function ($) {
 "use strict";

 const BUTTON_HEIGHT = 20;
 const SCREEN_PHONE = 768;
 const SCREEN_TABLET = 1024;

 // function to reposition splide arrows
 function repositionSplideArrow(willReset) {
  $(".splide").each((i, track) => {
   let $this = $(track);

   // find new relative height for the button
   let contentHeight = $this.find(".splide__track .is-active .cmp-teaser__content").outerHeight();
   let imgHeight = $this.find(".splide__track .is-active .cmp-teaser__image").outerHeight();
   let totalHeight = `${contentHeight + imgHeight}px`;
   let zeroMargin = false;

   if ($this.find(".splide__slide > .image").length || $this.find(".splide__slide > .text").length) {
    totalHeight = "auto";
   }

   const buttonHeight = $this.find(".splide__arrow").outerHeight();
   const newTotalHeightMobile = (imgHeight * 0.5);
   const newTotalHeightDesktop = imgHeight  + 41 * 0.5 + 77; // 72 px below the images

   $this.find(".splide__arrow").each((i, arrow) => {
    if (willReset) {
      $(arrow).attr("style", `top: ${newTotalHeightDesktop}px;`);
    } else {
      $(arrow).attr("style", `top: ${newTotalHeightMobile}px;`);
    }
   });
  });
 }

 // Only instaniate Splide when out of editing mode
 if (window.top && window.top.location && !window.top.location.href.includes("editor.html")) {
  // Remove AEM Arrows and Indicators
  $(".cmp-carousel__actions").remove();
  $(".cmp-carousel__indicators").remove();

  // Get All the carousel components
  const carousels = document.querySelectorAll(".carousel");
  const carouselTracks = document.querySelectorAll(".cmp-carousel");
  const carouselLists = document.querySelectorAll(".cmp-carousel__content");
  const carouselSlides = document.querySelectorAll(".cmp-carousel__item");

  // Add Splide Classes and remove AEM classes
  carouselSlides.forEach((el) => {
   el.classList.add("splide__slide");
   el.classList.remove("cmp-carousel__item");
   el.removeAttribute('aria-labelledby');
  });
  carouselLists.forEach((el) => {
   el.classList.add("splide__list");
   el.classList.remove("cmp-carousel__content");
  });
  carouselTracks.forEach((el) => {
   el.classList.add("splide__track");
   el.classList.remove("cmp-carousel");
  });

  // Instantiate Splide Carousels
  carousels.forEach((el, i) => {
   el.classList.add("splide");

   // Get total slides for progress bar
   if (carouselLists[i]) {
    el.setAttribute("data-totalslides", carouselLists[i].childElementCount);
   }

   // Initialize Splide Carousel
   const splideCarousel = new Splide(el, {
    type: "loop",
    pagination: false,
    perPage: 1,
    updateOnMove: true,
   });

   splideCarousel.on("click", function () {
    splideCarousel.go(">");
   });

   // Set Active slide for progress bar
   splideCarousel.on("move", (e) => {
    el.setAttribute("data-activeslide", e);
   });

   // Give cloned slide elements unique IDs
   splideCarousel.on("mounted", (e) => {
    const cloneSlides = document.querySelectorAll(".splide__slide--clone");

    cloneSlides.forEach((cloneSlide, i) => {
      const clonedIdEls = cloneSlide.querySelectorAll("[id]");

      clonedIdEls.forEach(clonedIdEl => {
        if (!clonedIdEl.id.includes("-clone")) {
          clonedIdEl.id += `-clone-${i}`;
        }
      });
    });
   });

   splideCarousel.on("active", () => {
    if ($(window).width() < SCREEN_TABLET) {
      repositionSplideArrow(false);
    } else {
     repositionSplideArrow(true);
    }
   });

   splideCarousel.mount();
  });

  // When window is resized, reposition splide arrows
  $(window).on("load resize", () => {
   if ($(this).width() < SCREEN_TABLET) {
    repositionSplideArrow(false);
  } else {
    repositionSplideArrow(true);
   }
  });

  $(".splide__arrow").on("focusin click keydown", (e) => {
   // reset active slides
   $(e.target)
    .closest(".splide")
    .find(" .teaser.animated")
    .each((e, teaser) => {
     $(teaser).removeClass("focused").find(".cmp-teaser").removeClass("focused");
    });
   if (e.type !== "click") {
    const $currTeaserElem = $(e.target).closest(".splide").find(".is-active .teaser.animated");
    $currTeaserElem.addClass("focused").find(".cmp-teaser").addClass("focused");
   }
  });

  $(".splide").on("focusout keydown", (e) => {
   let isFocusingOut = false,
    isLeavingPrevious = false,
    isLeavingNext = false;
   if (e.type === "focusout") {
    if (!$(e.relatedTarget).length) {
     isFocusingOut = true;
    }
   } else if (e.type === "keydown") {
    isLeavingPrevious = $(e.target).is("button.splide__arrow.splide__arrow--prev") && e.shiftKey && e.key === "Tab";
    isLeavingNext = $(e.target).is("button.splide__arrow.splide__arrow--next") && !e.shiftKey && e.key === "Tab";
    isFocusingOut = isLeavingPrevious || isLeavingNext;
   }
   if (isFocusingOut) {
    $(e.target)
     .closest(".splide")
     .find(".is-visible .teaser.animated")
     .removeClass("focused")
     .find(".cmp-teaser")
     .removeClass("focused");
   }
  });

  // Prevent carousel from moving to next slide
  // when action links are clicked
  $(".splide .cmp-teaser__action-link").click(function (event) {
   event.stopImmediatePropagation();
  });
 }
});
